import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import "./style.css";
import Navbar from './componants/navbar';
import Home from "./componants/pages/Home";
import ProjectsPage from "./componants/pages/Projects";
import MarkdownPage from "./componants/pages/MarkdownPageTemplate";
import GrindChecklist from "./componants/pages/GrindChecklist";
import BingoPage from "./componants/pages/Bingo";
import ErrorPage from "./componants/pages/ErrorPage";
import Container from './componants/container';
import { projectsData, socialsData } from "./extras/_modules/script.js";
import DocsData from "./extras/_modules/docs.mjs";
import BlogData from "./extras/_modules/blogs.mjs";
const HomeElement = (_jsx(Container, { children: _jsx(Home, {}) }));
const GrindElement = (_jsx(Container, { children: _jsx(GrindChecklist, {}) }));
const BingoElement = (_jsx(Container, { children: _jsx(BingoPage, {}) }));
const ErrorElement = (_jsx(Container, { children: _jsx(ErrorPage, {}) }));
const projectPages = projectsData.filter(p => p.isProject).map(project => {
    if (project.id === "BDOChecklist")
        return _jsx(Route, { path: project.url, element: GrindElement }, project.id);
    const routeArray = [];
    const ProjectElement = (_jsx(Container, { children: _jsx(ProjectsPage, { ...project }) }));
    // Project Page
    routeArray.push(_jsx(Route, { path: project.url, element: ProjectElement }, project.id));
    // Blog Page
    if (project.blog) {
        for (const key of Object.keys(BlogData)) {
            if (!key.startsWith(project.id))
                continue;
            const value = BlogData[key];
            const pageLocation = key.slice(project.id.length + 1); // ==> "role-types/index"				| "constructor"
            const directoryMap = pageLocation.split("/"); // ==> ["role-types", "index"]			| ["constructor"]
            const fileName = directoryMap.pop(); // ==> ["role-types"], "index"			| [], "constructor"
            const pageURL = `/${pageLocation}`;
            const fileURL = (fileName === "index") ? pageURL.slice(0, -6) : pageURL; // ==> "role-types"						| "constructor"
            const routeKey = `${project.id}_blog${fileURL.replace(/\//g, "_").replace(/-/g, "")}`;
            const routePath = `${project.url}/blog${fileURL}`;
            routeArray.push(_jsx(Route, { path: `/${routePath}`, element: _jsx(MarkdownPage, { markdown: value, baseUrl: project.url + "/blog" }) }, routeKey));
        }
    }
    // Documentation Pages
    if (project.docs) {
        for (const key of Object.keys(DocsData)) {
            if (!key.startsWith(project.id))
                continue;
            const value = DocsData[key];
            const pageLocation = key.slice(project.id.length + 1); // ==> "role-types/index"				| "constructor"
            const directoryMap = pageLocation.split("/"); // ==> ["role-types", "index"]			| ["constructor"]
            const fileName = directoryMap.pop(); // ==> ["role-types"], "index"			| [], "constructor"
            const pageURL = `/${pageLocation}`;
            const fileURL = (fileName === "index") ? pageURL.slice(0, -6) : pageURL; // ==> "role-types"						| "constructor"
            const routeKey = `${project.id}_docs${fileURL.replace(/\//g, "_").replace(/-/g, "")}`;
            const routePath = `${project.url}/docs${fileURL}`;
            routeArray.push(_jsx(Route, { path: `/${routePath}`, element: _jsx(MarkdownPage, { markdown: value, baseUrl: project.url + "/docs" }) }, routeKey));
        }
    }
    return routeArray;
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(React.StrictMode, { children: _jsxs(BrowserRouter, { children: [_jsx(Navbar, { projects: projectsData, socials: socialsData }), _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: HomeElement }), _jsx(Route, { path: "/bingo", element: BingoElement }), projectPages.flat(), _jsx(Route, { path: "*", element: ErrorElement })] })] }) }));
